import { useStoreActions, useStoreState } from 'easy-peasy';
import { IMainStoreModel, ServiceStatus } from '../../stores/easy-pease/store';
import { Proveedor } from '../../model/Proveedor';
import { IResult } from '../../model/Result';
import { useEffect } from 'react';

const BaseURL = process.env.REACT_APP_API_ENDPOINT + 'v1/proveedores/';

export const useProveedores = () => {
  useEffect(() => {
    resetServiceStatus();
  }, []);

  const setProveedores = useStoreActions<IMainStoreModel>((actions) => actions.setProveedores);
  const setEstadoServicioProveedores = useStoreActions<IMainStoreModel>(
    (actions) => actions.setEstadoServicioProveedores
  );

  const resetServiceStatus = () => {
    setEstadoServicioProveedores({ status: undefined })
  };

  const getAllProveedores = async () => {
    setEstadoServicioProveedores({ status: ServiceStatus.processing });

    try {
      const response = await fetch(BaseURL, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });

      if (!response.ok) {
        setEstadoServicioProveedores({ status: ServiceStatus.failed });
        throw new Error('Error en la carga de proveedores');
      }

      const jsonResult: IResult<Proveedor[]> = await response.json();
      setProveedores(jsonResult);
      setEstadoServicioProveedores({ status: ServiceStatus.processed });
    } catch (error) {
      setEstadoServicioProveedores({ status: ServiceStatus.failed });
      console.error(error);
      throw error;
    }
  };

  return { getAllProveedores, resetServiceStatus };
};
