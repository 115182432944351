import { Action, action, createStore } from "easy-peasy";
import { Proveedor } from "../../model/Proveedor";
import { Requerimiento } from "../../model/RequerimientoMateriales/Requerimiento";

export interface IMainStoreModel {
    requerimientoMateriales: Partial<Requerimiento> | undefined;
    setRequerimientoMateriales: Action<IMainStoreModel, Partial<Requerimiento>>;
    proveedores: Proveedor[] | undefined;
    setProveedores: Action<IMainStoreModel, Proveedor[]>;
    estadoServicioProveedores : IBaseServiceStatus | undefined,
    setEstadoServicioProveedores: Action<IMainStoreModel, IBaseServiceStatus>;
    estadoServicioRequerimientosMateriales : IBaseServiceStatus | undefined,
    setEstadoServicioRequerimientosMateriales: Action<IMainStoreModel, IBaseServiceStatus>;
}

export enum ServiceStatus {
    processing,
    processed,
    failed
}

export interface IBaseServiceStatus {
    status: ServiceStatus,
}

const mainStoreModel: IMainStoreModel = { 
  proveedores: undefined,
  setProveedores: action((state, payload) => {
    state.proveedores = payload;
  }),
  estadoServicioProveedores: undefined,
  setEstadoServicioProveedores: action((state, payload) => {
    state.estadoServicioProveedores = payload;
  }),
  requerimientoMateriales: undefined,
  setRequerimientoMateriales: action((state, payload) => {
    if (!payload) {
      state.requerimientoMateriales = undefined;
      return;
    }
    state.requerimientoMateriales = {
      ...state.requerimientoMateriales,
      ...payload,
    };
  }),
  estadoServicioRequerimientosMateriales: undefined,
  setEstadoServicioRequerimientosMateriales: action((state, payload) => {
    state.estadoServicioRequerimientosMateriales = payload;
  }),
  
}

// Creación store
const store = createStore<IMainStoreModel>(mainStoreModel);

export default store;