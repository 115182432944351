import { Remito } from "../model/Remito/Remito";
import { IResult } from "../model/Result";

// Functions
import * as Functions from '../redux/Functions/Commons';

const BaseURL = process.env.REACT_APP_API_ENDPOINT + 'v1/remitos';
console.log(BaseURL);



export const GetAllRemitos = async (
    token: string,
    pageNumber?: string,
    pageSize?: string,
    text?: string,
    filterOn?: string 
    ): Promise<Remito[]> => {
  
    let RequestURL: string = BaseURL;

    let params = null;

    params = new URLSearchParams({
        PageNumber: '1',
        PageSize: pageSize || '5000',
        SearchText: text || '',
        FilterOn: filterOn || ''
    });


    // if (pageNumber && pageSize && text) {
        
    // };

    // if (pageNumber && pageSize) {
    //     params = new URLSearchParams({
    //         PageNumber: pageNumber,
    //         PageSize: pageSize
    //     });
    // };

    // if (pageNumber && text) {
    //     params = new URLSearchParams({
    //         PageNumber: pageNumber,
    //         SearchText: text
    //     });
    // };

    // if (pageSize && text) {
    //     params = new URLSearchParams({
    //         PageSize: pageSize,
    //         SearchText: text
    //     });
    // };

    // if (pageNumber) {
    //     params = new URLSearchParams({
    //         PageNumber: pageNumber
    //     });
    // };

    // if (pageSize) {
    //     params = new URLSearchParams({
    //         PageSize: pageSize
    //     });
    // };

    // if (text && filterOn) {
    //     params = new URLSearchParams({
    //         SearchText: text,
    //         FilterOn: filterOn
    //     });
    // };

    var url = RequestURL + (params ? '?' + params.toString() : '');
    console.log(url);
    try {
      const Response: any = await fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        method: 'GET'
      })
        .then((response) => {
          if (!response.ok) {
            throw response;
          } else {
            return response.json() as Promise<
              IResult<any>
            >;
          }
        })
        .then((remitos: IResult<Remito[]>) => {
          return remitos.data;
        })
        .catch((error: any) => {
          if (error.text)
            return error.text().then((body: any) => {
              throw Functions.HttpErrorHandler(body, error);
            });
          else throw Functions.HttpErrorHandler(null, error);
        });
  
      return Response;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
};

export const GetRemitoById = async (remitoId: number, token: String): Promise<Remito> => {
    
    let RequestURL: string = BaseURL + remitoId;
    
    try {
        const Response: any = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'GET'
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<
                IResult<any>
            >;
            }
        })
        .then((remito: IResult<Remito>) => {
            return remito.data;
        })
        .catch((error: any) => {
            if (error.text)
            return error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            });
            else throw Functions.HttpErrorHandler(null, error);
        });
    
        return Response;
    } catch (error: any) {
        console.log(error);
        throw error;
    }
};

export const CreateRemito = async (newRemito: Remito, token: String): Promise<Remito> => {
        
    let RequestURL: string = BaseURL;
    
    try {
        const Response: any = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'POST',
        body: JSON.stringify(newRemito)
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<
                IResult<any>
            >;
            }
        })
        .then((remito: IResult<Remito>) => {
            return remito.data;
        })
        .catch((error: any) => {
            if (error.text)
            return error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            });
            else throw Functions.HttpErrorHandler(null, error);
        });
    
        return Response;
    } catch (error: any) {
        console.log(error);
        throw error;
    }
};

export const DeleteRemito = async (remitoId: number, token: String): Promise<number> => {
        
    let RequestURL: string = BaseURL + remitoId;
    
    try {
        const Response: any = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'DELETE'
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<
                IResult<any>
            >;
            }
        })
        .then((remito: IResult<Remito>) => {
            return remitoId;
        })
        .catch((error: any) => {
            if (error.text)
            return error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            });
            else throw Functions.HttpErrorHandler(null, error);
        });
    
        return Response;
    } catch (error: any) {
        console.log(error);
        throw error;
    }
};

export const UpdateRemito = async (remito: Remito, token: String): Promise<Remito> => {
            
    let RequestURL: string = BaseURL;
    
    try {
        const Response: any = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'PUT',
        body: JSON.stringify(remito)
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<
                IResult<any>
            >;
            }
        })
        .then((remito: IResult<Remito>) => {
            return remito.data;
        })
        .catch((error: any) => {
            if (error.text)
            return error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            });
            else throw Functions.HttpErrorHandler(null, error);
        });
    
        return Response;
    } catch (error: any) {
        console.log(error);
        throw error;
    }
};

