import { Requerimiento } from "../model/RequerimientoMateriales/Requerimiento";
import { IResult } from "../model/Result";

// Functions
import * as Functions from '../redux/Functions/Commons';

const BaseURL = process.env.REACT_APP_API_ENDPOINT + 'v1/requerimientos-materiales/';
console.log(BaseURL);

export const GetAllRequerimientos = async (
    token: string,
    pageNumber?: string,
    pageSize?: string,
    text?: string,
    filterOn?: string 
    ): Promise<Requerimiento[]> => {
  
    let RequestURL: string = BaseURL;

    let params = null;

    if (pageNumber && pageSize && text) {
        params = new URLSearchParams({
            PageNumber: pageNumber,
            PageSize: pageSize,
            SearchText: text
        });
    };

    if (pageNumber && pageSize) {
        params = new URLSearchParams({
            PageNumber: pageNumber,
            PageSize: pageSize
        });
    };

    if (pageNumber && text) {
        params = new URLSearchParams({
            PageNumber: pageNumber,
            SearchText: text
        });
    };

    if (pageSize && text) {
        params = new URLSearchParams({
            PageSize: pageSize,
            SearchText: text
        });
    };

    if (pageNumber) {
        params = new URLSearchParams({
            PageNumber: pageNumber
        });
    };

    if (pageSize) {
        params = new URLSearchParams({
            PageSize: pageSize
        });
    };

    if (text && filterOn) {
        params = new URLSearchParams({
            SearchText: text,
            FilterOn: filterOn
        });
    };

    var url = RequestURL + (params ? '?' + params.toString() : '');
    console.log(url);
    try {
      const Response: any = await fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        method: 'GET'
      })
        .then((response) => {
          if (!response.ok) {
            throw response;
          } else {
            return response.json() as Promise<
              IResult<any>
            >;
          }
        })
        .then((requerimientos: IResult<Requerimiento[]>) => {
          return requerimientos.data;
        })
        .catch((error: any) => {
          if (error.text)
            return error.text().then((body: any) => {
              throw Functions.HttpErrorHandler(body, error);
            });
          else throw Functions.HttpErrorHandler(null, error);
        });
  
      return Response;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
};

export const GetRequerimientoById = async (requerimientoId: number, token: String): Promise<Requerimiento> => {
    
    let RequestURL: string = BaseURL + requerimientoId;
    
    try {
        const Response: any = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'GET'
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<
                IResult<any>
            >;
            }
        })
        .then((requerimiento: IResult<Requerimiento>) => {
            return requerimiento.data;
        })
        .catch((error: any) => {
            if (error.text)
            return error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            });
            else throw Functions.HttpErrorHandler(null, error);
        });
    
        return Response;
    } catch (error: any) {
        console.log(error);
        throw error;
    }
};

export const DeleteRequerimiento = async (requerimientoId: number, token: String): Promise<number> => {
        
    let RequestURL: string = BaseURL + requerimientoId;
    
    try {
        const Response: any = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'DELETE'
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<
                IResult<any>
            >;
            }
        })
        .then(() => {
            return requerimientoId;
        })
        .catch((error: any) => {
            if (error.text)
            return error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            });
            else throw Functions.HttpErrorHandler(null, error);
        });
    
        return Response;
    } catch (error: any) {
        console.log(error);
        throw error;
    }
};

export const UpdateRequerimiento = async (requerimiento: Requerimiento, token: String): Promise<Requerimiento> => {
            
    let RequestURL: string = BaseURL;
    
    try {
        const Response: any = await fetch(RequestURL, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        method: 'PUT',
        body: JSON.stringify(requerimiento)
        })
        .then((response) => {
            if (!response.ok) {
            throw response;
            } else {
            return response.json() as Promise<
                IResult<any>
            >;
            }
        })
        .then((requerimiento: IResult<Requerimiento>) => {
            return requerimiento.data;
        })
        .catch((error: any) => {
            if (error.text)
            return error.text().then((body: any) => {
                throw Functions.HttpErrorHandler(body, error);
            });
            else throw Functions.HttpErrorHandler(null, error);
        });
    
        return Response;
    } catch (error: any) {
        console.log(error);
        throw error;
    }
};
