import {
  FaCog,
  FaFile,
  FaHome,
  FaIndustry,
  FaPaintRoller,
  FaWpforms
} from 'react-icons/fa';
import { MenuItem } from './MenuItem';

export const MenuItems: MenuItem[] = [
  {
    title: 'Principal',
    name: 'home',
    location: '/home',
    icon: () => <FaHome />,
    Group: 1,
    roles: ['order_manager', 'sistem_administrators']
  },
  {
    title: 'Productos',
    name: 'productos',
    location: '/productos',
    icon: () => <FaWpforms />,
    Group: 1,
    roles: ['sistem_administrators']
  },
  {
    title: 'Materias Primas',
    name: 'supplies',
    location: '/supplies',
    icon: () => <FaPaintRoller />,
    Group: 1,
    roles: ['sistem_administrators']
  },
  {
    title: 'Ordenes de fabricación',
    name: 'ordenes',
    location: '/ordenes',
    icon: () => <FaIndustry />,
    Group: 1,
    roles: ['order_manager', 'sistem_administrators']
  },
  {
    title: 'Remitos',
    name: 'remitos',
    location: '/remitos',
    icon: () => <FaFile />,
    Group: 1,
    roles: ['sistem_administrators']
  },
  {
    title: 'Requerimientos de Materiales',
    name: 'requerimientos-materiales',
    location: '/requerimientos-materiales',
    icon: () => <FaFile />,
    Group: 1,
    roles: ['sistem_administrators']
  },
  {
    title: 'Configuraciones',
    name: 'settings',
    location: '/settings',
    icon: () => <FaCog />,
    Group: 2,
    roles: ['sistem_administrators']
  }
];
