import * as Products from './Productos';
import * as Formulas from './Formula';
import * as Authentication from './authentication';
import * as OrdenFabricacion from './OrdenFabricacion';

// Reducers
import * as SupplyReducer from './../redux/Reducers/SuppliesReducer';
import * as StockReducer from '../redux/Reducers/StockReducer';
import * as OperatorReducer from './../redux/Reducers/OperatorReducer';
import * as OrderReducer from '../redux/Reducers/OrderReducer';
import * as RemitoReducer from '../redux/Reducers/RemitoReducer';
import * as RequerimientoReducer from '../redux/Reducers/RequerimientoReducer';

import * as Menu from './menu';

// States
import { StockState } from '../redux/States/StockState';
import { OperatorState } from './../redux/States/OperatorState';
import { SuppliesState } from './../redux/States/SuppliesState';
import { OrderState } from '../redux/States/OrderState';
import { RemitoState } from '../redux/States/RemitoState';
import { RequerimientoState } from '../redux/States/RequerimientoState';

export const reducers = {
  productos: Products.reducer,
  Stock: StockReducer.stockReducer,
  Supplies: SupplyReducer.reducer,
  Operators: OperatorReducer.OperatorReducer,
  formulas: Formulas.reducer,
  ordenes: OrdenFabricacion.reducer,
  authentication: Authentication.reducer,
  menu: Menu.reducer,
  orders: OrderReducer.OrderReducer,
  remitos: RemitoReducer.RemitoReducer,
  requerimientos: RequerimientoReducer.RequerimientoReducer,
};

export interface ApplicationState {
  readonly productos: Products.ProductState | undefined;
  readonly Stock: StockState | undefined;
  readonly Supplies: SuppliesState | undefined;
  readonly Operators: OperatorState | undefined;
  readonly formulas: Formulas.FormulaState | undefined;
  readonly ordenes: OrdenFabricacion.OrdenesState | undefined;
  readonly authentication: Authentication.AuthenticateState | undefined;
  readonly menu: Menu.RouteState | undefined;
  readonly orders: OrderState | undefined;
  readonly remitos: RemitoState | undefined;
  readonly requerimientos: RequerimientoState | undefined;
}

export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
