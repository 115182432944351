import { Document, Image, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { Requerimiento } from "../../../model/RequerimientoMateriales/Requerimiento";
import moment from "moment";
import logo from '../../../assets/logo.png';

export const RequerimientoMaterialesPDFContent: React.FC<{item: Requerimiento }> = ({ item }) => {
  const styles = StyleSheet.create({
    pageHeader: {
      fontSize: 20,
      marginBottom: 10,
    },
    contentPage: {
      fontSize: 12,
      marginBottom: 10,
    },
    table: {
      display: 'flex', // Usa flex para emular tablas
      flexDirection: 'column', // Las filas estarán en una columna
      marginBottom: 10,
    },
    tableRow: {
      display: 'flex', // Cada fila es un contenedor flex
      flexDirection: 'row', // Los elementos de cada fila estarán en una fila
    },
    tableCol: {
      flex: 1, // Distribución equitativa de las columnas
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#000',
      padding: 5,
    },
    tableCell: {
      fontSize: 10,
    },  
  });
  
  return (
  <Document>
    <Page size="A4" style={{ padding: 20 }}>
      <View style={[styles.pageHeader, { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }]}>
        <Text>Requerimiento de Material Nro. '{item.codigo}' versión '{item.version}'</Text>
        <Image
          src={logo}
          style={{ width: 100, height: 50 }}
        />
      </View>
      <View style={[styles.contentPage]}>
        <View>
          <Text>Fecha: {new Date().toLocaleDateString()}</Text>
          <Text>Proveedor: {item.proveedor.codigo}</Text>
          <Text>Teléfono: {item.proveedor.telefono}</Text>
          <Text>Email: {item.proveedor.mail}</Text>
          <Text>Contacto: {item.proveedor.contacto}</Text>
        </View>
      </View>
      <View style={styles.table}>
        <View style={[styles.tableRow, { backgroundColor: '#e0e0e0' }]}>
          <Text style={[styles.tableCol, styles.tableCell]}>Artículo</Text>
          <Text style={[styles.tableCol, styles.tableCell]}>Descripción</Text>
          <Text style={[styles.tableCol, styles.tableCell]}>Cantidad</Text>
          <Text style={[styles.tableCol, styles.tableCell]}>Fecha Entrega</Text>
          <Text style={[styles.tableCol, styles.tableCell]}>Precio</Text>
          <Text style={[styles.tableCol, styles.tableCell]}>Total</Text>
        </View>
        {item.detalles.map((detalle, index) => (
          <View key={index} style={styles.tableRow}>
            <Text style={[styles.tableCol, styles.tableCell]}>{detalle.materiaPrima?.codigo || ''}</Text>
            <Text style={[styles.tableCol, styles.tableCell]}>{detalle.materiaPrima?.descripcion || ''}</Text>
            <Text style={[styles.tableCol, styles.tableCell]}>{detalle.cantidad || ''}</Text>
            <Text style={[styles.tableCol, styles.tableCell]}>{moment(detalle.fechaEntrega).format('YYYY-MM-DD')}</Text>
            <Text style={[styles.tableCol, styles.tableCell]}>{detalle.precio || ''}</Text>
            <Text style={[styles.tableCol, styles.tableCell]}>{(detalle.cantidad * detalle.precio).toFixed(2)}</Text>
          </View>
        ))}
        <View style={styles.tableRow}>
          <Text style={[styles.tableCol, styles.tableCell]}></Text>
          <Text style={[styles.tableCol, styles.tableCell]}></Text>
          <Text style={[styles.tableCol, styles.tableCell]}></Text>
          <Text style={[styles.tableCol, styles.tableCell, { fontWeight: 'bold' }]}>
            Total:
          </Text>
          <Text style={[styles.tableCol, styles.tableCell]}>
            {item.detalles
              .reduce((sum, detalle) => sum + detalle.cantidad * detalle.precio, 0)
              .toFixed(2)}
          </Text>
          <Text style={[styles.tableCol, styles.tableCell]}>
            {item.detalles
              .reduce(
                (sum, detalle) => sum + detalle.cantidad * detalle.precio * 1.21,
                0
              )
              .toFixed(2)}
          </Text>
        </View>
      </View>
      <View style={styles.contentPage}>
        <Text>Plazo de pago: {item.plazoDias} días</Text>
      </View>
    </Page>
  </Document>
)};