import { useState } from "react";
import { useHistory } from 'react-router-dom';
import RequerimientoMaterialesListComponent from "../components/RequerimientoMateriales/List/RequerimientoMaterialesList.Component";

export const RequerimientoMaterialesPage = () => {
    
  const [ShowForm, setShowForm] = useState<boolean>(false);
  
  let history = useHistory();

  return (
    <>
      <div className="pageHeader">
        <h2>Requerimientos de materiales</h2>
        <button
          className="btn btn-primary"
          onClick={() => {
            setShowForm(true);
            history.push("requerimientos-materiales/nuevo");
          }}
        >
          Nuevo requerimiento de materiales
        </button>
      </div>
        <RequerimientoMaterialesListComponent />
    </>
  );
      
}