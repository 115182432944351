import { ErrorType } from '../../../model/FetchError';
import { ApplicationState } from '../../../stores';

//stores 
import * as RequerimientoStore from '../../../redux/Middleware/RequerimientoMiddleware';
import { Requerimiento } from '../../../model/RequerimientoMateriales/Requerimiento';   

//#region Props

interface IProps {}

interface ConnectedProps {
    isLoading: boolean | undefined;
    LoadingSuccessfully: boolean | undefined;
    failOnLoading: boolean | undefined;
    failOnDelete: boolean | undefined;
    error: ErrorType | undefined;
    requerimientos: Requerimiento[] | undefined;
    isFilteres: boolean | undefined;
    filterCriteria: string | undefined;
}

interface MedicalServicesDispatchProps {
    GetAll: typeof RequerimientoStore.actionCreators.GetAll;
    Delete: typeof RequerimientoStore.actionCreators.Delete;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: ApplicationState, ownProps: IProps) => ({
    isLoading: state.requerimientos?.isLoadingAll,
    LoadingSuccessfully: state.requerimientos?.isLoadingAllSuccess,
    failOnLoading: state.requerimientos?.FailGettingAll,
    failOnDelete: state.requerimientos?.FailDeletingOne,
    error: state.requerimientos?.Error,
    requerimientos: state.requerimientos?.list || [],
    isFilteres: state.requerimientos?.isFiltered,
    filterCriteria: state.requerimientos?.filterCriteria
});

export const mapDispatchToProps = {
    ...RequerimientoStore.actionCreators
};

//#endregion Props