import { AppThunkAction } from '../../stores';
import { ErrorType } from '../../model/FetchError';

// services
import * as Services from '../../services/RequerimientosServices';

// actions
import * as Actions from '../Actions/RequerimientoActions';

type KnownAction =
    | Actions.Requerimiento_GetAll_Request_Action
    | Actions.Requerimiento_GetAll_Success_Action
    | Actions.Requerimiento_GetAll_Fail_Action
    | Actions.Requerimiento_Get_Request_Action
    | Actions.Requerimiento_Get_Success_Action
    | Actions.Requerimiento_Get_Fail_Action
    | Actions.Requerimiento_Update_Request_Action
    | Actions.Requerimiento_Update_Success_Action
    | Actions.Requerimiento_Update_Fail_Action
    | Actions.Requerimiento_Delete_Request_Action
    | Actions.Requerimiento_Delete_Success_Action
    | Actions.Requerimiento_Delete_Fail_Action;


export const actionCreators = {
    GetAll: (
        pageNumber?: string,
        pageSize?: string,
        text?: string,
        filterOn?: string //Parametro para filtrado;
    ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        var state = getState();

        var token = state.authentication?.authenticatedUser?.jwToken as string;

        dispatch({
            type: 'REQUERIMIENTO_GETALL_REQUEST',
            isFiltered: text?.length ? true : false,
            filterCriteria: text
        });

        Services.GetAllRequerimientos(token, pageNumber, pageSize, text, filterOn)
            .then((requerimientos) => {
                dispatch({
                    type: 'REQUERIMIENTO_GETALL_SUCCESS',
                    requerimientos: requerimientos
                });
            })
            .catch((error: ErrorType) =>
                dispatch({
                    type: 'REQUERIMIENTO_GETALL_FAIL',
                    Error: error
                })
            );

        },
    Get: (requerimientoId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            var state = getState();

            var token = state.authentication?.authenticatedUser?.jwToken as string;

            if(state.requerimientos?.list?.find(x => x.id === requerimientoId) === undefined) {

                dispatch({
                    type: 'REQUERIMIENTO_GET_REQUEST',
                    requerimientoId: requerimientoId
                });
    
                Services.GetRequerimientoById(requerimientoId, token)
                .then((requerimiento) => {
                    dispatch({
                        type: 'REQUERIMIENTO_GET_SUCCESS',
                        requerimiento: requerimiento
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'REQUERIMIENTO_GET_FAIL',
                        Error: error
                    })
                );

            }
        },
    Update: (requerimiento: any): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            var state = getState();

            var token = state.authentication?.authenticatedUser?.jwToken as string;

            dispatch({
                type: 'REQUERIMIENTO_UPDATE_REQUEST',
                requerimiento: requerimiento
            });

            Services.UpdateRequerimiento(requerimiento, token)
                .then((requerimiento) => {
                    dispatch({
                        type: 'REQUERIMIENTO_UPDATE_SUCCESS',
                        requerimiento: requerimiento
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'REQUERIMIENTO_UPDATE_FAIL',
                        Error: error
                    })
                );

        },
    Delete: (requerimientoId: number): AppThunkAction<KnownAction> =>
        async (dispatch, getState) => {
            var state = getState();

            var token = state.authentication?.authenticatedUser?.jwToken as string;

            dispatch({
                type: 'REQUERIMIENTO_DELETE_REQUEST',
                requerimientoId: requerimientoId
            });

            Services.DeleteRequerimiento(requerimientoId, token)
                .then(() => {
                    dispatch({
                        type: 'REQUERIMIENTO_DELETE_SUCCESS',
                        requerimientoId: requerimientoId
                    });
                })
                .catch((error: ErrorType) =>
                    dispatch({
                        type: 'REQUERIMIENTO_DELETE_FAIL',
                        Error: error
                    })
                );
        }
};