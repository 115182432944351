import { Requerimiento } from "../../model/RequerimientoMateriales/Requerimiento";
import { CommonState } from "../../stores/common/commonState";

export interface RequerimientoState extends CommonState {
    cancelingOrder: boolean;
    canceledOrderSuccessfuly: boolean | undefined;
    failOnCancelingOrder: boolean;
    list: Requerimiento[];
  }
  
  export const requerimientoUnloadedState: RequerimientoState = {
    isLoadingAll: false,
    isLoadingAllSuccess: undefined,
    isLoadingOne: false,
    isLoadingOneSuccess: undefined,
    isSaving: false,
    isSavingSuccess: undefined,
    isDeletingOne: false,
    isDeletingOneSuccess: undefined,
    isAdding: false,
    isAddingSuccess: undefined,
    FailAdding: false,
    FailSaving: false,
    FailDeletingOne: false,
    FailGettingAll: false,
    FailGettingOne: false,
    isFiltered: false,
    filterCriteria: undefined,
    Error: undefined,
    list: [],
    cancelingOrder: false,
    canceledOrderSuccessfuly: undefined,
    failOnCancelingOrder: false,
  };