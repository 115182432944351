// actions 
import { Action, Reducer } from 'redux';
import * as Actions from '../Actions/RequerimientoActions';
import { RequerimientoState, requerimientoUnloadedState } from '../States/RequerimientoState';
//import { RemitoState, remitoUnloadedState } from '../States/RemitoState';

type KnownAction = 
    | Actions.Requerimiento_GetAll_Request_Action
    | Actions.Requerimiento_GetAll_Success_Action
    | Actions.Requerimiento_GetAll_Fail_Action
    | Actions.Requerimiento_Get_Request_Action
    | Actions.Requerimiento_Get_Success_Action
    | Actions.Requerimiento_Get_Fail_Action
    | Actions.Requerimiento_Update_Request_Action
    | Actions.Requerimiento_Update_Success_Action
    | Actions.Requerimiento_Update_Fail_Action
    | Actions.Requerimiento_Delete_Request_Action
    | Actions.Requerimiento_Delete_Success_Action
    | Actions.Requerimiento_Delete_Fail_Action;

export const RequerimientoReducer: Reducer<RequerimientoState> = (state: RequerimientoState | undefined, incomingAction: Action): RequerimientoState => {

    if (state === undefined) {
        return requerimientoUnloadedState as RequerimientoState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'REQUERIMIENTO_GETALL_REQUEST':
            return {
                ...state,
                isLoadingAll: true,
                isLoadingAllSuccess: undefined,
                FailGettingAll: false,
                Error: undefined,
                isFiltered: action.isFiltered,
                filterCriteria: action.filterCriteria
            };
        case 'REQUERIMIENTO_GETALL_SUCCESS':
            return {
                ...state,
                isLoadingAll: false,
                isLoadingAllSuccess: true,
                FailGettingAll: false,
                list: action.requerimientos.sort((a, b) => a.id - b.id),
                Error: undefined
            };
        case 'REQUERIMIENTO_GETALL_FAIL':
            return {
                ...state,
                isLoadingAll: false,
                isLoadingAllSuccess: false,
                FailGettingAll: true,
                Error: action.Error
            };
        case 'REQUERIMIENTO_GET_REQUEST':
            return {
                ...state,
                isLoadingOne: true,
                isLoadingOneSuccess: undefined,
                FailGettingOne: false,
                Error: undefined
            };
        case 'REQUERIMIENTO_GET_SUCCESS':
            return {
                ...state,
                isLoadingOne: false,
                isLoadingOneSuccess: true,
                FailGettingOne: false,
                list: [...state.list.filter((remito) => remito.id !== action.requerimiento.id), action.requerimiento].sort((a, b) => a.id - b.id),
                Error: undefined
            };
        case 'REQUERIMIENTO_GET_FAIL':
            return {
                ...state,
                isLoadingOne: false,
                isLoadingOneSuccess: false,
                FailGettingOne: true,
                Error: action.Error
            };
        case 'REQUERIMIENTO_UPDATE_REQUEST':
            return {
                ...state,
                isSaving: true,
                isSavingSuccess: undefined,
                FailSaving: false,
                Error: undefined
            };
        case 'REQUERIMIENTO_UPDATE_SUCCESS':
            return {
                ...state,
                isSaving: false,
                isSavingSuccess: true,
                FailSaving: false,
                list: [...state.list.filter((remito) => remito.id !== action.requerimiento.id), action.requerimiento].sort((a, b) => a.id - b.id),
                Error: undefined
            };
        case 'REQUERIMIENTO_UPDATE_FAIL':
            return {
                ...state,
                isSaving: false,
                isSavingSuccess: false,
                FailSaving: true,
                Error: action.Error
            };
        case 'REQUERIMIENTO_DELETE_REQUEST':
            return {
                ...state,
                isDeletingOne: true,
                isDeletingOneSuccess: undefined,
                FailDeletingOne: false,
                Error: undefined
            };
        case 'REQUERIMIENTO_DELETE_SUCCESS':
            return {
                ...state,
                isDeletingOne: false,
                isDeletingOneSuccess: true,
                FailDeletingOne: false,
                list: [...state.list.filter((remito) => remito.id !== action.requerimientoId)].sort((a, b) => a.id - b.id),
                Error: undefined
            };
        case 'REQUERIMIENTO_DELETE_FAIL':
            return {
                ...state,
                isDeletingOne: false,
                isDeletingOneSuccess: false,
                FailDeletingOne: true,
                Error: action.Error
            };
        default:
            return state;
    }
}