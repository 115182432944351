import { useStoreActions, useStoreState } from "easy-peasy";
import { Requerimiento } from "../../model/RequerimientoMateriales/Requerimiento";
import { IMainStoreModel, ServiceStatus } from "../../stores/easy-pease/store";
import { IResult } from "../../model/Result";
import { useEffect } from "react";

const BaseURL = process.env.REACT_APP_API_ENDPOINT + 'v1/requerimientos-materiales/';

export const useRequerimientosMateriales = () => {
  useEffect(() => {
    resetServiceStatus();
  }, []);
  
  const setEstadoServicioRequerimientosMateriales = useStoreActions<IMainStoreModel>(
    (actions) => actions.setEstadoServicioRequerimientosMateriales
  );

  const requerimientoMateriales:Requerimiento = useStoreState<IMainStoreModel>((state) => state.requerimientoMateriales);
  const setRequerimientoMateriales = useStoreActions<IMainStoreModel>(
    (actions) => actions.setRequerimientoMateriales
  );

  const resetServiceStatus = () => {
    setEstadoServicioRequerimientosMateriales({ status: undefined })
  };

  const getRequerimientoById = async (id: number | string, token: string, callbackSuccess: Function) => {
    setEstadoServicioRequerimientosMateriales({ status: ServiceStatus.processing });

    try {
      const response = await fetch(`${BaseURL}${id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        method: 'GET',
      });

      if (!response.ok) {
        setEstadoServicioRequerimientosMateriales({ status: ServiceStatus.failed });
        throw new Error('Error en la carga de proveedores');
      }

      const jsonResult: IResult<any> = await response.json();

      const requerimiento = {
        id: jsonResult.data.id,
        codigo: jsonResult.data.codigo,
        version: jsonResult.data.version,
        proveedor: {
          codigo: jsonResult.data.codigoproveedor,
          nombre: jsonResult.data.nombreproveedor,
          telefono: jsonResult.data.telefono,
          mail: jsonResult.data.mail,
          contacto: jsonResult.data.contacto,
        },
        totalSinIva: jsonResult.data.totalSinIva,
        totalConIva: jsonResult.data.totalConIva,
        plazoDias: jsonResult.data.plazoDias,
        detalles: jsonResult.data.requerimientos_detalles.map((detalle: any) => ({
          materiaPrima: {
            id: detalle.materiaPrima.id,
            codigo: detalle.materiaPrima.codigo,
            descripcion: detalle.materiaPrima.descripcion,
          },
          cantidad: detalle.cantidad,
          fechaEntrega: new Date(detalle.fechaEntrega),
          precio: detalle.precio,
          montoSinIva: detalle.montoSinIva,
          montoConIva: detalle.montoConIva,
        })),
      };

      setRequerimientoMateriales(requerimiento);
      setEstadoServicioRequerimientosMateriales({ status: ServiceStatus.processed });
      callbackSuccess(requerimiento);
    } catch (error) {
      setEstadoServicioRequerimientosMateriales({ status: ServiceStatus.failed });
      console.error(error);
      throw error;
    }
  };

  const createRequerimientoMateriales = async (value: Requerimiento, token: string, callbackSuccess: Function): Promise<number> => {
    setEstadoServicioRequerimientosMateriales({ status: ServiceStatus.processing });

    const body = {
      codigo: value.codigo, 
      codigoproveedor: value.proveedor.codigo,
      telefono: value.proveedor.telefono,
      mail: value.proveedor.mail,
      contacto: value.proveedor.contacto,
      plazoDias: value.plazoDias,
      requerimientos_detalles: value.detalles.map((d) => ({
        cantidad: d.cantidad,
        fechaEntrega: d.fechaEntrega,
        precio: d.precio,
        materiaPrimaId: d.materiaPrima.id,
      })),
    }
    try {
      const response = await fetch(BaseURL, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        method: 'POST',
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        setEstadoServicioRequerimientosMateriales({ status: ServiceStatus.failed });
        throw new Error('Error en la carga de proveedores');
      }
      const jsonResult: {id:number} = await response.json();
      setRequerimientoMateriales({
        ...requerimientoMateriales,
        ...{
          id: jsonResult.id,
        }
      });
      setEstadoServicioRequerimientosMateriales({ status: ServiceStatus.processed });
      callbackSuccess(jsonResult.id, true);
      return jsonResult.id;
    } catch (error) {
      setEstadoServicioRequerimientosMateriales({ status: ServiceStatus.failed });
      console.error(error);
      throw error;
    }
  };

  const updateRequerimientoMateriales = async (value: Requerimiento, token: string, callbackSuccess: Function) => {
    setEstadoServicioRequerimientosMateriales({ status: ServiceStatus.processing });

    const body = {
      id: value.id,
      codigo: value.codigo, 
      codigoproveedor: value.proveedor.codigo,
      telefono: value.proveedor.telefono,
      mail: value.proveedor.mail,
      contacto: value.proveedor.contacto,
      plazoDias: value.plazoDias,
      requerimientos_detalles: value.detalles.map((d) => ({
        cantidad: d.cantidad,
        fechaEntrega: d.fechaEntrega,
        precio: d.precio,
        materiaPrimaId: d.materiaPrima.id,
      })),
    }
    try {
      const response = await fetch(`${BaseURL}${value.id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        method: 'PUT',
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        setEstadoServicioRequerimientosMateriales({ status: ServiceStatus.failed });
        throw new Error('Error en la carga de proveedores');
      }
      setEstadoServicioRequerimientosMateriales({ status: ServiceStatus.processed });
      callbackSuccess();
    } catch (error) {
      setEstadoServicioRequerimientosMateriales({ status: ServiceStatus.failed });
      console.error(error);
      throw error;
    }
  };

  return { resetServiceStatus, createRequerimientoMateriales, updateRequerimientoMateriales, getRequerimientoById };
};
